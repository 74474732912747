import React, { useState, useEffect, useRef } from 'react';
import useViewport from 'Common/hooks/useViewport';

import { Wrapper, Nav, List, Item, Link, Span } from './Breadcrumbs.styled';

export interface Props {
    backgroundColourWhite?: boolean;
    items: {
        id: string;
        label: string;
        href?: string;
    }[];
}

const Breadcrumbs: React.FC<Props> = ({ items, backgroundColourWhite }) => {
    const { width } = useViewport();
    const [navWidth, setNavWidth] = useState<number | null>(null);
    const [truncate, setTruncate] = useState<boolean>(false);
    const navRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        if (navRef.current.scrollWidth) {
            const currentNavWidth = navRef.current.scrollWidth;
            setNavWidth(currentNavWidth);
            setTruncate(currentNavWidth > width - 40);
        }
    }, [width]);

    return (
        <Wrapper>
            <Nav
                aria-label="Breadcrumbs"
                className="c-breadcrumbs"
                $backgroundColourWhite={backgroundColourWhite}
                ref={navRef}
            >
                <List>
                    {items.map((breadcrumb, index) => {
                        const isFirst = index === 0;
                        const isLast = index === items.length - 1;

                        return (
                            <Item
                                key={`breadcrumb-${breadcrumb.id}`}
                                $overflowHidden={navWidth && truncate && isLast ? true : false}
                            >
                                {breadcrumb.href ? (
                                    <Link
                                        label={
                                            truncate && isFirst
                                                ? '🏠'
                                                : truncate && !isFirst && !isLast
                                                ? '...'
                                                : breadcrumb.label
                                        }
                                        href={breadcrumb.href as string}
                                        aria-current={isLast ? 'page' : undefined}
                                        className="c-breadcrumbs-link"
                                        prefetch={false}
                                        title={breadcrumb.label}
                                    />
                                ) : (
                                    <Span
                                        aria-current={isLast ? 'page' : undefined}
                                        title={breadcrumb.label}
                                    >
                                        {truncate && !isFirst && !isLast ? '...' : breadcrumb.label}
                                    </Span>
                                )}
                            </Item>
                        );
                    })}
                </List>
            </Nav>
        </Wrapper>
    );
};

export default Breadcrumbs;
