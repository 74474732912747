import styled, { css } from 'styled-components';

import BaseLink from 'Elements/Link';

export const Wrapper = styled.div`
    position: relative;
`;

export const Nav = styled.nav<{ $backgroundColourWhite?: boolean }>`
    ${({ theme, $backgroundColourWhite }) => css`
        position: absolute;
        top: 1.5rem;
        left: 0;
        z-index: 5;
        padding: 0.25rem;
        width: calc(100% - 1rem);

        @media ${theme.breakpoints.mediumSmall.media} {
            width: unset;
        }

        @media ${theme.breakpoints.large.media} {
            top: 2.5rem;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: ${$backgroundColourWhite
                ? `${theme.colors.primaryContrast.hex}`
                : `${theme.components.breadcrumbs.background}`};
            opacity: ${$backgroundColourWhite ? 1 : 0.85};
            border-radius: 0 0.25rem 0.25rem 0;
        }
    `}
`;

export const List = styled.ol`
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    padding: 0.375rem 0.5rem 0.375rem 1.25rem;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        padding: 0.25rem 1.5rem 0.4rem 2.5rem;
    }
`;

export const Item = styled.li<{ $overflowHidden: boolean }>`
    ${({ theme, $overflowHidden }) => css`
        font-weight: 300;
        position: relative;
        line-height: 1.2;
        font-size: 0.875rem;
        max-width: 100%;

        ${$overflowHidden &&
        css`
            overflow: hidden;
        `}

        @media ${theme.breakpoints.large.media} {
            font-size: 1.125rem;
        }

        &:not(:first-of-type) {
            padding-left: 0.4rem;
        }

        &:not(:last-of-type) {
            padding-right: 0.4rem;

            &:after {
                content: '/';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(50%, -50%);
            }
        }

        &:last-of-type {
            font-weight: 700;
        }
    `}
`;

const SpanLinkStyles = css`
    ${({ theme }) => css`
        display: block;
        color: ${theme.colors.text.hex};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: inherit;
        font-size: inherit;
        line-height: 1.2;
        max-width: 100%;
    `}
`;

export const Link = styled(BaseLink)`
    ${SpanLinkStyles}
`;
export const Span = styled.span`
    ${SpanLinkStyles}
`;
